import React from 'react'
import styled from 'styled-components'
import { spacing, tokens, media, box } from '@tokens'
import { ComponentBase } from '@mixins/MixinsComponent'

export const SeriesContainer = styled.section`
  /* ${ComponentBase} */
  ${box({
    spacing: 'top',
    multiplier: 0.5,
  })};
  ${box({
    spacing: 'bottom',
    type: 'margin',
  })};

  .inner {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: ${tokens.get('maxWidth.wide')};
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .beers__list {
    flex: 1 1 auto;
  }

  .beer-side-bar {
    width: 11rem;
  }
`
export default ({ children, isFlex, size }) => {
  return (
    <SeriesContainer size={size} isFlex={isFlex}>
      <div className="inner">{children}</div>
    </SeriesContainer>
  )
}
