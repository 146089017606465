import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useContext,
} from 'react'
import styled, { css } from 'styled-components'
import SideBarNav from '@components/SideBarNav'
import styledMap from 'styled-map'
import Image from '@elements/Image'
import { spacing, tokens, media, box } from '@tokens'

export const $SeriesIntroText = styled.div`
  ${box({
    spacing: 'top',
    multiplier: 0.5,
    type: 'margin',
  })};
  display: block;
  width: 100%;
  max-width: ${tokens.get('maxWidth.wide')};
  margin-left: auto;
  margin-right: auto;

  .inner {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: auto;
    margin-right: auto;
  }

  .container {
    flex: 1 1 auto;
    padding: ${spacing(4)} ${spacing(3)};
    border: 1px solid ${tokens.color('border')};
    border-radius: ${spacing(1)};
    max-width: 22rem;
    margin-left: auto;
    margin-right: auto;

    ${media.greaterThan('m')`
      padding: ${spacing(6)} ${spacing(5)};
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      max-width: none;
    `}
  }

  .content {
    flex: 1 1 auto;
    align-self: center;
    margin-bottom: ${spacing(3)};

    ${media.greaterThan('m')`
      margin-bottom: 0;
      padding-right: ${spacing(5)};
    `}

    ${media.greaterThan('l')`
      padding-right: ${spacing(5)};
    `}

    p {
      margin: 0;
    }
  }

  .spacer {
    display: none;

    ${media.greaterThan('1000px')`
      display: block;
      flex: 0 0 11rem;
      padding: 0;
    `}
  }

  .icons {
    flex: 0 1 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
    margin-left: 0;
    margin-right: auto;
    width: 100%;
    max-width: 17.5rem;

    ${media.greaterThan('m')`
      flex: 0 0 auto;
      margin-left: auto;
      margin-right: 0;
    `}

    ${media.greaterThan('l')`
      max-width: 20rem;
    `}
  }

  .size {
    filter: invert();
    mix-blend-mode: multiply;
    margin-left: ${(32 / 320) * 100}%;

    &:first-child {
      margin-left: 0;
    }

    &--kegs {
      width: ${(90 / 320) * 100}%;
    }

    &--growlers {
      width: ${(52 / 320) * 100}%;
    }

    &--bottles-all {
      width: ${(52 / 380) * 100}%;
    }

    &--cans-all {
      width: ${(46 / 380) * 100}%;
    }
  }
`

export default function SeriesIntroText({ allBeerFormats }) {
  const beerFormats = allBeerFormats
    .filter((format) => {
      return ['kegs', 'growlers', 'cans-all', 'bottles-all'].includes(
        format.slug
      )
    })
    .map((format) => {
      let order
      if (format.slug.includes('kegs')) {
        order = 1
      } else if (format.slug.includes('growlers')) {
        order = 2
      } else if (format.slug.includes('bottles')) {
        order = 3
      } else if (format.slug.includes('cans')) {
        order = 4
      }

      return {
        ...format,
        order,
      }
    })
    .sort((a, b) => {
      return a.order > b.order ? 1 : -1
    })

  return (
    <$SeriesIntroText>
      <div className="inner">
        <div className="container">
          <div className="content">
            <p>
              Our beers come in all shapes and sizes. Select a brew below to
              view its available formats.
            </p>
          </div>
          <div className="icons">
            {beerFormats &&
              beerFormats.map(({ name, slug, contentSizeFormats }) => {
                const className = `size--${slug}`
                const icon = contentSizeFormats?.icon || {}

                return (
                  <div key={slug} className={`size ${className}`}>
                    <Image className="image" source={icon} />
                  </div>
                )
              })}
          </div>
        </div>
        <SideBarNav className="spacer">&nbsp;</SideBarNav>
      </div>
    </$SeriesIntroText>
  )
}
