import React from 'react'
import styled from 'styled-components'

export const SeriesBlock = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
`

export default ({ children }) => {
  return <SeriesBlock>{children}</SeriesBlock>
}
