import React from 'react'
import { Link } from '@elements/Link'
import styled from 'styled-components'
import { spacing, tokens, media } from '@tokens'

export const SeriesContent = styled.div`
  position: relative;
  z-index: ${tokens.z('content')};
  display: block;
  margin-top: ${spacing(5)};
  padding-top: ${spacing(4)};
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  ${media.greaterThan('l')`
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  `}

  .beer-series__description {
    flex: 0 1 25.3em;
  }

  .beer-series__link {
    margin-top: ${spacing(3)};

    ${media.greaterThan('l')`
      flex: 0 0 auto;
      margin-left: ${spacing(4)};
      margin-top: 0;
    `}
  }
`

export default ({ description, link }) => {
  return (
    <SeriesContent>
      <div className="beer-series__description">
        <p dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      {link && <Link link={link} hasArrow className="beer-series__link" />}
    </SeriesContent>
  )
}
