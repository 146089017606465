import React from 'react'
import styled from 'styled-components'
import { box } from '@tokens'

export const SeriesList = styled.div`
  ${box({
    spacing: 'bottom',
    multiplier: 0.5,
  })}
  /*
    Hack 10% is arbitrary.
    If no value is give the values for background text are calculated incorrect.
    By providing a width and flex config it sets the original size correctly
    while responding as expected.
  */
  flex: 1 1 10%;
  width: 10%;

  > div {
    ${box({
      spacing: 'top',
    })}

    &:first-child {
      /*
        Pull up the series to remove the space for the first child
        This still allows the box spacing for smooth scroll
      */
      ${box({
        spacing: 'top',
        multiplier: -1,
        type: 'margin',
      })}
    }
  }
`

export default ({ children }) => {
  return <SeriesList>{children}</SeriesList>
}
