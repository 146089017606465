import React, { useState } from 'react'
import InView from 'react-intersection-observer'
import SideBarNav from '@components/SideBarNav'
import SideBarNavItem from '@components/SideBarNav/SideBarNavItem'
import SideBarNavBlockWrap from '@components/SideBarNav/SideBarNavBlockWrap'
import SeriesContainer from './SeriesContainer'
import SeriesList from './SeriesList'
import SeriesBlock from './SeriesBlock'
import SeriesContent from './SeriesContent'
import SeriesBackgroundText from './SeriesBackgroundText'
import SeriesBeerList from './SeriesBeerList'

export default ({ allSeries }) => {
  const [activeSideNav, setSideNavActive] = useState(allSeries[0].slug)
  const sideNavSlugs = allSeries
    .filter(({ slug, beers }) => {
      return beers ? slug : null
    })
    .map(({ slug }) => slug)
  return (
    <SeriesContainer isFlex size="wide">
      <SeriesList>
        {allSeries.map(({ beers, name, slug, description, contentSeries }) => {
          if (!beers) return null
          const link = contentSeries?.textContent?.link
          const icon = contentSeries?.icon
          let teaserVariant
          switch (slug) {
            case 'archived':
              teaserVariant = 'artwork'
              break
            case 'mixer-packs':
              teaserVariant = 'box'
              break
          }

          return (
            <SideBarNavBlockWrap
              key={slug}
              slug={slug}
              navList={sideNavSlugs}
              activeSideNav={activeSideNav}
              setSideNavActive={setSideNavActive}
            >
              <SeriesBlock>
                <SeriesBackgroundText
                  teaserVariant={teaserVariant}
                  name={name}
                  hasFade
                />
                <SeriesBeerList
                  beers={beers}
                  icon={icon}
                  teaserVariant={teaserVariant}
                />
                {description && (
                  <SeriesContent description={description} link={link} />
                )}
              </SeriesBlock>
            </SideBarNavBlockWrap>
          )
        })}
      </SeriesList>
      <SideBarNav className="beer-side-bar">
        {allSeries.map(({ beers, name, slug }) => {
          if (!beers) return null
          return (
            <SideBarNavItem
              key={slug}
              slug={slug}
              name={name}
              isActive={activeSideNav === slug}
            />
          )
        })}
      </SideBarNav>
    </SeriesContainer>
  )
}
