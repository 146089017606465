import React from 'react'
import { graphql } from 'gatsby'
import Container from '@layout/Container'
import Series, { SeriesIntroText } from '@components/Series'

import { formatTemplateData } from '@utils/templates'
import { useTallestInRow } from '@hooks/useTallestInRow'

export const BeersPage = ({
  data: {
    allWpSeries: { nodes: allSeries },
    allWpSizeFormat: { nodes: allBeerFormats },
    allWpMixerPack: { nodes: allWpMixerPacks = [] },
  },
}) => {
  const title = 'Our Beers'
  const template = {
    activeSlug: 'beers',
    heroType: 'small',
    theme: 'light',
    title,
    seo: {
      title: `${title} | Hoyne Brewing Co. | Craft Beer in Victoria`,
      path: '/beers/',
    },
  }

  allSeries.sort(function (a, b) {
    return a.menuOrder - b.menuOrder
  })

  const imageSlugValues = {
    'small-can': 0,
    'large-can': 1,
    'small-bottle': 2,
    'large-bottle': 3,
  }

  allSeries = allSeries
    .map((series, seriesIndex) => {
      if (series.beers?.nodes?.length) {
        series.beers = [
          ...series.beers.nodes.sort(function (a, b) {
            return a.menuOrder - b.menuOrder
          }),
        ]
        series.beers = useTallestInRow(series.beers)
      }
      return series
    })
    .filter((series) => series.beers.length)

  if (allWpMixerPacks.length) {
    allSeries.splice(allSeries.length - 1, 0, {
      name: 'Mixer Packs',
      slug: 'mixer-packs',
      beers: allWpMixerPacks.map((mixerPack) => {
        return {
          ...mixerPack,
          contentBeer: mixerPack.contentMixerPack,
        }
      }),
    })
  }

  return (
    <Container template={formatTemplateData(template)}>
      <div className="inner">
        <SeriesIntroText allBeerFormats={allBeerFormats} />
        <Series allSeries={allSeries} />
      </div>
    </Container>
  )
}

export default BeersPage

export const pageQuery = graphql`
  query SeriesQuery {
    allWpSeries(sort: { order: ASC, fields: menuOrder }) {
      nodes {
        id
        slug
        name
        description
        menuOrder
        beers {
          nodes {
            id
            slug
            title
            uri
            menuOrder
            contentBeer {
              featureImageType
              smallBottleImage {
                altText
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              largeBottleImage {
                altText
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              smallCanImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 560, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              largeCanImage {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 560, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              labelArtwork {
                altText
                localFile {
                  extension
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 512, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
        contentSeries {
          textContent {
            link {
              fieldGroupName
              text
              type
              data {
                target
                title
                url
              }
            }
            content
            selectedFields
            heading
          }
        }
      }
    }
    allWpMixerPack(sort: { fields: date, order: DESC }) {
      nodes {
        id
        slug
        title
        uri
        contentMixerPack {
          featureImageType
          image {
            altText
            localFile {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    allWpSizeFormat {
      nodes {
        slug
        name
        contentSizeFormats {
          label
          icon {
            localFile {
              childImageSharp {
                fluid(maxWidth: 100, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
